import React from "react"
import * as styles from "./partnersHeader.module.scss"

export default function PartnersHeader() {
  return (
    <>
      <h2>
        Повышенный кэшбэк
        <br />в магазинах наших
        <br className="d-md-none" /> партнеров
      </h2>
      <p className={styles.hint}>
        Более 260 000 магазинов
        <br />
        на любой вкус
      </p>
    </>
  )
}
